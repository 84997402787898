import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Image, Row } from 'react-bootstrap';
import logo from '../../../assets/logo.svg';
import { LanguageProvider } from '../../../core/language-provider';


export default function FooterBlock() {

    useEffect(() => {

    }, []);



    return (
        <>
            {/* <Container id="footer" fluid className='p-3 bg-black'>
                <Row lg={3}>
                    <Col>
                        <Image src={logo} width={200} className='mb-2' />
                        <div className='d-grid gap-0'>
                            <p className='text-white text-uppercase mb-0'>P.IVA {LanguageProvider.get('footer.iva')}</p>
                            <p className='text-white text-uppercase mb-0'>{LanguageProvider.get('footer.via')}</p>
                            <p className='text-white text-uppercase mb-0'>{LanguageProvider.get('footer.citta')}, {LanguageProvider.get('footer.provincia')}</p>
                            <p className='text-white text-uppercase mb-0'>{LanguageProvider.get('footer.stato')}</p>
                        </div>
                    </Col>
                    <Col></Col>
                    <Col></Col>

                </Row>

            </Container> */}
            <footer className="page-footer font-small blue pt-4" style={{ backgroundColor: 'black' }}>
                <div className="container-fluid text-center text-md-left">
                    <div className="row d-flex flex-row justify-content-center">
                        <div className="col-md-3 col-sm-12 mt-md-0 mt-3 mb-3">
                            <Image src={logo} width={200} className='mb-2' />
                            <div className='d-grid gap-0'>
                                <p className='text-white text-uppercase mb-0'>P.IVA {LanguageProvider.get('footer.iva')}</p>
                                <p className='text-white text-uppercase mb-0'>{LanguageProvider.get('footer.via')}</p>
                                <p className='text-white text-uppercase mb-0'>{LanguageProvider.get('footer.citta')}, {LanguageProvider.get('footer.provincia')}</p>
                                <p className='text-white text-uppercase mb-0'>{LanguageProvider.get('footer.stato')}</p>
                            </div>
                        </div>

                        <div className="col-md-2 col-sm-12 mb-md-0 mb-3">
                            <h5 className="text-uppercase text-white">Home&Living</h5>
                            <ul className="list-unstyled">
                                <li><a href="/home-living" className='text-white'>Spring Summer 2025</a></li>
                                <li><a href="/home-living" className='text-white'>Fuori collezione</a></li>
                            </ul>
                        </div>

                        <div className="col-md-2 col-sm-12 mb-md-0 mb-3">
                            <h5 className="text-uppercase text-white">Fashion</h5>
                            <ul className="list-unstyled">
                                <li><a href="/collection" className='text-white'>Nuova Collezione 2025</a></li>
                                <li><a href="/collection" className='text-white'>Fuori collezione</a></li>
                            </ul>
                        </div>

                        <div className="col-md-3 col-sm-12 mb-md-0 mb-3">
                            <h5 className="text-uppercase text-white">Social</h5>
                            <ul className="list-unstyled">
                                <li><a href="https://www.facebook.com/annamariamadeinitaly" className='text-white'>Facebook</a></li>
                                <li><a href="https://www.instagram.com/annamariagroup/" className='text-white'>Instagram</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='text-center'>

                </div>

                <div className="footer-copyright text-white text-center py-3">
                    <p>© 2024 Copyright @Annamaria srl</p>
                    Powered by <a href="https://gruppoyec.com/" className='text-white me-5'>Gruppo YEC</a>
                    <a href="https://annamariagroup.com/privacy-policy/" className='text-white me-5'>Privacy Policy</a>
                    <a href="https://annamariagroup.com/wp-content/uploads/2024/02/privacy-annamaria-group.pdf" className='text-white'>Privacy Policy Clienti / Fornitori</a>
                </div>

            </footer>
        </>
    )
};